



































import { Component, Vue } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
const { Splide, SplideSlide } = require("@splidejs/vue-splide");
import StatisticsService from "@/services/StatisticsService";
import BaseWrapper from "../Base/BaseWrapper.vue";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: {
        Splide,
        SplideSlide,

        BaseWrapper,
    },
})
export default class LandingGalleries extends Vue {
    public isLoaded: boolean = false;

    public options: any = {
        rewind: true,
        perPage: 1,
        autoplay: true,
        pauseOnHover: false,
        arrows: "slider",
    };

    public topGalleries: any[] = [];

    get images(): any[] {
        let images: any[] = [];
        const items: string[] = [
            "image_0_path",
            "image_1_path",
            "image_2_path",
        ];

        this.topGalleries.map((gallery: any) => {
            const isImage0Exist: boolean = !!gallery.image_0_path;
            const isImage1Exist: boolean = !!gallery.image_1_path;
            const isImage2Exist: boolean = !!gallery.image_2_path;
            if (isImage0Exist) images.push(gallery.image_0_path);
            if (isImage1Exist) images.push(gallery.image_1_path);
            if (isImage2Exist) images.push(gallery.image_2_path);
        });

        return images;
    }

    async getTopGalleries() {
        const year = new Date().getFullYear();

        try {
            const response = await StatisticsService.getTopGalleries(year);

            if (!(response && response.data))
                return { errors: [{ id: "", message: "Something wrong" }] };

            const { galleries } = response.data;

            this.topGalleries = galleries;
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    async mounted() {
        await this.getTopGalleries();
        setTimeout(() => {
            this.isLoaded = true;
        }, 100);
    }
}
