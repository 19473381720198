




















import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import LandingCompanyList from "@/components/Landing/CompanyList.vue";
import LandingGalleries from "@/components/Landing/Galleries.vue";
import LandingHero from "@/components/Landing/Hero.vue";
import LandingNavigationBar from "@/components/Landing/NavigationBar.vue";
import LandingStatistics from "@/components/Landing/Statistics.vue";
import SharedPlanList from "@/components/Shared/Plan/List.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        LandingNavigationBar,
        LandingHero,
        LandingCompanyList,
        LandingStatistics,
        SharedPlanList,

        LandingGalleries,
    },
})
export default class RootHome extends Vue {
    get plans() {
        return this.$store.state.plans.plans;
    }

    async mounted() {
        const plans = await this.$store.dispatch("plans/getAllPlan");
    }
}
